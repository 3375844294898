import { createRouter, createWebHistory } from 'vue-router'
// import SignInView from '../views/Sign/LoginView.vue'
// import SignUpView from '../views/Sign/SignupView.vue'
// import LandingView from '../views/LandingView.vue'
// import ScheduleView from '../views/Schedule/ScheduleView.vue'
// import ConsultView from '../views/Consult/ConsultView.vue'
// import DoctorView from '../views/Doctor/DoctorView.vue'
// import PatientView from '../views/Patient/PatientView.vue'

const routes = [
    // 관리자
    {
        path: '/admin/signin',
        name: 'adminSignin',
        // component: SignInView,
        component: () => import('../views/Sign/LoginView.vue'),
    },
    {
        path: '/admin/consult',
        name: 'adminConsult',
        // component: ConsultView,
        component: () => import('../views/Admin/ConsultView.vue'),
        props: true,
        meta: { title: '협진', requiresAuth: true }
    },
    // 의료진
    {
        path: '/signin',
        name: 'signin',
        // component: SignInView,
        component: () => import('../views/Sign/LoginView.vue'),
    },
    {
        path: '/signup',
        name: 'signup',
        // component: SignUpView,
        component: () => import('../views/Sign/SignupView.vue'),
    },
    {
        path: '/findId',
        name: 'findId',
        // component: SignUpView,
        component: () => import('../views/Sign/FindId.vue'),
    },
    {
        path: '/ResetPw',
        name: 'ResetPw',
        // component: SignUpView,
        component: () => import('../views/Sign/ResetPw.vue'),
    },
    {
        path: '/',
        name: 'landing',
        // component: LandingView,
        component: () => import('../views/LandingView.vue'),
        props: true,
        meta: { title: '홈 피드', requiresAuth: true }
    },
    {
        path: '/schedule',
        name: 'schedule',
        // component: ScheduleView,
        component: () => import('../views/Schedule/ScheduleView.vue'),
        props: true,
        meta: { title: '스케쥴', requiresAuth: true }
    },
    {
        path: '/consult-request',
        name: 'requestConsult',
        // component: ConsultView,
        component: () => import('../views/Consult/RequestListView.vue'),
        props: true,
        meta: { title: '협진', requiresAuth: true }
    },
    {
        path: '/consult-advice',
        name: 'adviceConsult',
        // component: ConsultView,
        component: () => import('../views/Consult/AdviceListView.vue'),
        props: true,
        meta: { title: '협진', requiresAuth: true }
    },
    {
        path: '/consult/:id',
        name: 'consultdetail',
        // component: PatientDetailView,
        component: () => import('../views/Consult/DetailView.vue'),
        props: true,
        meta: { title: '협진 상세', requiresAuth: true }
    },
    {
        path: '/consult/:id/room',
        name: 'consultroom',
        // component: PatientDetailView,
        component: () => import('../views/Consult/RoomView.vue'),
        props: true,
        meta: { title: '협진방', requiresAuth: true }
    },
    {
        path: '/consult/:id/advice',
        name: 'consultadvice',
        // component: PatientDetailView,
        component: () => import('../views/Consult/Advice.vue'),
        props: true,
        meta: { title: '협진방', requiresAuth: true }
    },
    {
        path: '/consult/:requestId/schedule',
        name: 'ConsultScheduleView',
        component: () => import('../views/Consult/ScheduleView.vue'),
        props: true,
        meta: { title: '협진 의뢰인 스케쥴', requiresAuth: true }
    },
    {
        path: '/consult/record',
        name: 'ConsultRecordView',
        component: () => import('../views/Consult/RecordView.vue'),
        props: true,
        meta: { title: '협진 기록지', requiresAuth: true }
    },
    {
        path: '/referral-form',
        name: 'referralform',
        // component: PatientDetailView,
        component: () => import('../views/Consult/ReferralFormView.vue'),
        props: true,
        meta: { title: '신규 협진', requiresAuth: true }
    },
    {
        path: '/doctor',
        name: 'doctor',
        // component: DoctorView,
        component: () => import('../views/Doctor/ListView.vue'),
        props: true,
        meta: { title: '의료진', requiresAuth: true }
    },
    {
        path: '/doctor/:id',
        name: 'doctordetail',
        component: () => import('../views/Doctor/DetailView.vue'),
        props: true,
        meta: { title: '의료진 상세', requiresAuth: true }
    },
    {
        path: '/doctor/:doctorId/schedule',
        name: 'DoctorScheduleView',
        component: () => import('../views/Doctor/ScheduleView.vue'),
        props: true,
        meta: { title: '의료진 스케쥴', requiresAuth: true }
    },
    {
        path: '/patient',
        name: 'patient',
        // component: PatientView,
        component: () => import('../views/Patient/ListView.vue'),
        props: true,
        meta: { title: '환자', requiresAuth: true }
    },
    {
        path: '/patient/:id',
        name: 'patientdetail',
        component: () => import('../views/Patient/DetailView.vue'),
        props: true,
        meta: { title: '환자 상세', requiresAuth: true }
    },
    {
        path: '/pacs/:id',
        name: 'pacs',
        component: () => import('../views/Patient/PacsDetail.vue'),
        props: true,
        meta: { title: 'PACS', requiresAuth: true }
    },
    {
        // path: '/dicom/:id',
        path: '/dicom',
        name: 'dicomView',
        component: () => import('../views/Patient/DicomView.vue'),
        // props: true,
        props: (route) => ({ url: route.query.url }),
        meta: { title: 'dicom', requiresAuth: true }
    },
    {
        path: '/multi-dicom-viewer',
        name: 'multiDicomViewer',
        component: () => import('../views/Patient/MultiDicomViewer.vue'),
        props: (route) => ({ url: route.query.url }),
        meta: { title: 'multi-dicom-viewer', requiresAuth: true }
    },
    {
        path: '/setting',
        name: 'setting',
        component: () => import('../views/Setting/SettingView.vue'),
        props: true,
        meta: { title: '설정', requiresAuth: true }
    },
    {
        path: '/mypage',
        name: 'mypage',
        component: () => import('../views/Mypage/MypageView.vue'),
        props: true,
        meta: { title: '내 정보', requiresAuth: true }
    },
    {
        path: '/template',
        name: 'template',
        component: () => import('../views/TemplateView.vue'),
        props: true,
        meta: { title: '템플릿', requiresAuth: true }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

// router.beforeEach((to, from, next) => {
//     document.title = to.meta.title || 'Moree Linker';

//     const isLoginPage = to.path === '/signin' ||  to.path === '/admin/signin' || to.path === '/resetPw' || to.path === '/findId' || to.path === '/signup'
//     const isAuthenticated = checkAuthentication();

//     if(to.path === '/signin'||  to.path === '/admin/signin' || to.path === '/resetPw' || to.path === '/findId' || to.path === '/signup') {
//         // 로그인 세션 삭제
//         sessionStorage.removeItem('session');
//         sessionStorage.setItem('session', JSON.stringify({isLogin: false}));

//         // 토큰 쿠키 삭제
//         deleteCookie("token");
//     }

//     if (!isLoginPage && !isAuthenticated) {
//         // 인증이 필요한 페이지에 로그인되어 있지 않으면 로그인 페이지로 리다이렉트
//         next({ path : '/signin' });

//     } else if(to.path == '/signin' && isAuthenticated) {
//         // 로그인 세션이 존재하는데 로그인 페이지로 이동한다면
//         next({ path : '/' });

//     } else {
//         // 인증이 필요하지 않거나 인증된 경우 정상적으로 진행
//         next();
//     }
// });



router.beforeEach((to, from, next) => {
    document.title = to.meta.title || 'Moree Linker';

    const isLoginPage = ['/signin', '/admin/signin', '/resetPw', '/findId', '/signup'].includes(to.path);
    const isAuthenticated = checkAuthentication();

    // 로그인 세션과 사용자 권한 정보 가져오기
    const session = JSON.parse(sessionStorage.getItem('session') || '{}');
    const userInfo = session.userInfo || {};
    const permissionType = userInfo.permissionType;

    if (isLoginPage) {
        // 로그인 관련 페이지 접근 시 세션 초기화 및 토큰 삭제
        sessionStorage.removeItem('session');
        sessionStorage.setItem('session', JSON.stringify({ isLogin: false }));
        deleteCookie("token");
    }

    if (!isLoginPage && !isAuthenticated) {
        // 인증이 필요한 페이지에 로그인되어 있지 않으면 로그인 페이지로 리다이렉트
        next({ path: '/signin' });
    } else if (to.path === '/signin' && isAuthenticated) {
        // 이미 로그인된 상태에서 로그인 페이지 접근 시 홈으로 리다이렉트
        next({ path: '/' });
    } else if (permissionType === 'SUPERUSER') {
        // ADMIN 사용자가 아닌 경로로 접근 시 admin/consult로 리다이렉트
        if (!to.path.startsWith('/admin') && to.path !== '/admin/consult') {
            next({ path: '/admin/consult' });
        } else {
            next();
        }
    } else if (permissionType === 'USER') {
        // USER 사용자가 admin/consult 접근 시 홈으로 리다이렉트
        if (to.path === '/admin/consult') {
            next({ path: '/' });
        } else {
            next();
        }
    } else {
        // 인증이 필요하지 않거나 인증된 경우 정상적으로 진행
        next();
    }
});




// router.beforeEach((to, from, next) => {
//     document.title = to.meta.title || 'Moree Linker';

//     const isLoginPage = to.path === '/signin' ||  to.path === '/admin/signin' || to.path === '/resetPw' || to.path === '/findId' || to.path === '/signup'
//     const isAuthenticated = checkAuthentication();

//     if(to.path === '/signin'||  to.path === '/admin/signin' || to.path === '/resetPw' || to.path === '/findId' || to.path === '/signup') {
//         // 로그인 세션 삭제
//         sessionStorage.removeItem('session');
//         sessionStorage.setItem('session', JSON.stringify({isLogin: false}));

//         // 토큰 쿠키 삭제
//         deleteCookie("token");
//     }

//     if (!isLoginPage && !isAuthenticated) {
//         // // 인증이 필요한 페이지에 로그인되어 있지 않으면 로그인 페이지로 리다이렉트
//         // next({ path : '/signin' });

//         if(to.path.includes('admin')) {
//             next({ path : '/admin/signin' });

//         } else {
//             next({ path : '/signin' });
//         }

//     } else if(to.path == '/signin' && isAuthenticated) {
//         // 로그인 세션이 존재하는데 로그인 페이지로 이동한다면
//         next({ path : '/' });

//     } else {
//         // 인증이 필요하지 않거나 인증된 경우 정상적으로 진행
//         next();
//     }

//     if(sessionStorage.getItem('session')) {
//         let permissionType = 'user';
//             permissionType = JSON.parse(sessionStorage.getItem('session')).userInfo.permissionType.toLowerCase();
//         if(permissionType == 'admin') {
//             if(!to.path.includes('admin')) {
//                 next({ path : '/admin/consult' });

//             } else {
//                 next();
//             }

//         } else {
//             if(to.path.includes('admin')) {
//                 next({ path : '/' });

//             } else {
//                 next();
//             }
//         }
//     } else {
//         next();
//     }
// });

// 쿠키 체크
function checkCookie(name) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // 쿠키의 이름으로 시작하는지 확인
        if (cookie.startsWith(`${name}=`)) {
            return true; // 해당 이름의 쿠키(토큰)이 존재함
        }
    }
    return false; // 해당 이름의 쿠키(토큰)이 존재하지 않음
}

function checkAuthentication() {
    // 세션 여부 확인 로직
    if(sessionStorage.getItem('session') && checkCookie("token")) {
        const loginExpirationTime = JSON.parse(sessionStorage.getItem('session')).expiresAt;

        const expiryDate = new Date(loginExpirationTime);
        const currentDate = new Date();

        if (currentDate > expiryDate) {
            // alert('토큰 시간 만료됨');
            sessionStorage.removeItem('session');

            localStorage.clear();
            deleteCookie("token");
            return false;
        } else {
            return true;
        }
    }
}

// 쿠키 삭제
export function deleteCookie(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    return true;
}

export default router
